var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"wiki-td",class:{
    'text-center': _vm.centered, 
    'capabilities-success': _vm.wikiData.level === 'success', 
    'capabilities-warning': _vm.wikiData.level === 'warning', 
    'capabilities-danger': _vm.wikiData.level === 'danger'
    }},[(_vm.wikiData.value && _vm.wikiData.value.length !== 0)?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.wikiData.value,_vm.sigFigs)))]):_c('span',[_c('small',[_vm._v(" - ")])]),(_vm.wikiData.additional)?_c('br'):_vm._e(),_c('text-truncated',{attrs:{"text":_vm.wikiData.additional
        ? _vm.$options.filters.formatCurrency(_vm.wikiData.additional, _vm.sigFigsAdditional)
        : '',"max-length":30,"element":"small"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }