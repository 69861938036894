<template>
  <div class="country-selector">
    <div class="row">
      <div class="col">
        <h5 class="mt-2">Country Select</h5>
        <div class="panel-body mb-2">
          <div class="row">
            <div class="col-9">
              <div class="form-group">
                <label for="inputContinent">Select Continent</label>
                <!-- TODO: Load the continents from the server or use an env variable, as diff clients are defining their own regions and using those, instead -->
                <v-select
                  :options="['Africa', 'Asia Pacific', 'Europe', 'America']"
                  id="inputContinent"
                  :value="continent"
                  :clearable="false"
                  @input="changeContinent"
                >
                </v-select>
                <div v-if="$store.getters.loadingCountries" class="mt-4">
                  <span id="imgLoading">
                    <i class="fa fa-sync fa-spin"></i>&nbsp; Getting
                    countries...
                  </span>
                </div>
                <div v-else-if="!$store.getters.loadingCountries && storeCountries.length === 0" class="mt-4">
                  <em><i class="fa fa-info-circle"></i>&nbsp;No countries found for the selected continent</em>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="pull-right">
                <button
                  id="selectAllCountries"
                  class="btn btn-primary btn-sm mt-1 hidden"
                  @click="checkAllCountries"
                >
                  <i class="fa fa-check-square"></i>&nbsp;Select All
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mt-3">
            <div class="col">
              <div
                v-if="storeCountries.length > 0"
                class="row countriesContainer"
              >
                <div
                  v-for="country in storeCountries"
                  :key="country.country_id"
                  class="col-12 col-md-6 col-lg-4"
                >
                  <div
                    class="form-group"
                    v-b-tooltip.hover.left="{
                      title: 'No Representation',
                      disabled: !country.noRepresentation,
                      variant: 'info'
                    }"
                  >
                    <input
                      class="form-control form-control-sm"
                      type="checkbox"
                      @change="checkCountry(country.country_id, $event)"
                      :id="country.iso_code_3"
                      :value="country.country_id"
                      :checked="country.checked"
                      :disabled="country.noRepresentation"
                    />
                    &nbsp;
                    <label
                      :for="country.iso_code_3"
                      :class="{ 'text-muted': country.noRepresentation }"
                    >
                      {{ country.country_name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inquiry-actions>
      <button
        class="btn btn-success btn-md float-right"
        :disabled="checkCapabilitiesButtonDisabled"
        @click="$emit('check-capabilities')"
      >
        <i class="fa fa-search"></i>&nbsp;Check Capabilities
      </button>
    </inquiry-actions>
  </div>
</template>

<style scoped>
.countriesContainer .form-group input[type="checkbox"].form-control {
  width: 50px;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0.6rem;
}

.countriesContainer .form-group label {
  position: absolute;
  top: 2px;
  left: 70px;
}
</style>
<script>
import InquiryActions from './InquiryActions';
import FeaturesMixin from '@/mixins/features.mixin';
export default {
  name: "CountrySelector",
  components: { InquiryActions },
  mixins: [FeaturesMixin],
  props: {
    checkCapabilitiesButtonDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    continent: "Europe",
  }),
  computed: {
    storeCountries() {
      return this.$store.getters.getCountriesByContinent(this.continent);
    }
  },
  methods: {
    changeContinent(value) {
      this.continent = value;
      this.$store.dispatch("loadCountriesForContinent", { continent: value });
    },
    checkCountry(value, event) {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: value,
        checked: event.target.checked
      });
      this.$emit('change-input', {type: 'country'});
    },
    checkAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: true,
        continent: this.continent
      });
    }
  },
  mounted() {
    if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA)) {
      this.continent = "Africa";
    }

    this.changeContinent(this.continent);

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });
  }
};
</script>
