<template>
  <td class="wiki-td" 
    :class="{
      'text-center': centered, 
      'capabilities-success': wikiData.level === 'success', 
      'capabilities-warning': wikiData.level === 'warning', 
      'capabilities-danger': wikiData.level === 'danger'
      }">
    <span v-if="wikiData.value && wikiData.value.length !== 0">{{
      wikiData.value | formatCurrency(sigFigs)
    }}</span>
    <span v-else><small> - </small></span>
    <br v-if="wikiData.additional" />
    <text-truncated
      :text="
        wikiData.additional
          ? $options.filters.formatCurrency(wikiData.additional, sigFigsAdditional)
          : ''
      "
      :max-length="30"
      element="small"
    ></text-truncated>
    <!--    <small v-html="wikiData.additional"></small>-->
  </td>
</template>

<script>
import TextTruncated from "./TextTruncated";
export default {
  name: "WikiTableData",
  components: {
    TextTruncated
  },
  props: {
    wikiData: Object,
    centered: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    sigFigs() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData.value);
    },
    sigFigsAdditional() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData.additional);
    }
  },
  methods: {}
};
</script>
