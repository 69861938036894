import axios from "axios";

export const wiki = {
  state: () => ({
    contactDetails: [],
    classType: null,
    classTypes: [],
    policyClass: null,
    policyClasses: [],
    currencyCodes: [],
    currencies: [],
    selectedCurrencyCode: "USD",
    selectedCurrencyRate: 1.0,
    currencyCodeFilter: "",
    loadingWikiData: false,
  }),
  mutations: {
    changeClassOfBusiness(state, payload) {
      state.classType = payload.classType;
    },
    changePolicyCLass(state, payload) {
      state.policyClass = payload.policyClass;
    },
    setPolicyClasses(state, { policyClasses }) {
      state.policyClasses = policyClasses;
    },
    loadWikiStateFromStorage(state, payload) {
      state.contactDetails = payload.contactDetails;
      state.classType = payload.classType;
      state.policyClass = payload.policyClass;
      state.selectedCurrencyCode = payload.selectedCurrencyCode
        ? payload.selectedCurrencyCode
        : "USD";
    },
    setCurrencyCodes(state, payload) {
      state.currencyCodes = payload.currencyCodes;
    },
    setCurrencies(state, { currencies }) {
      state.currencies = currencies;
    },
    setCurrencyCode(state, payload) {
      state.selectedCurrencyCode = payload.code;
      const currency = state.currencies.find(currency => currency.code === state.selectedCurrencyCode);
      if (currency !== undefined) {
        state.selectedCurrencyRate = currency.rate;
      }
      state.policyLimit = 0;
    },
    setCurrencyCodeFilter(state, payload) {
      if (
        state.currencyCodes.filter(code =>
          code.startsWith(payload.filter.toUpperCase())
        ).length
      ) {
        state.currencyCodeFilter = payload.filter;
      }
    },
    setCurrencyData(state, payload) {
      state.selectedCurrencyCode = payload.currencyCode
        ? payload.currencyCode
        : state.selectedCurrencyCode;
    },
    setLoadingWikiData(state, { loading }) {
      state.loadingWikiData = loading;
    }
  },
  actions: {
    async getWikiCapabilitiesData({ state, commit, rootGetters }) {
      commit("setLoadingWikiData", { loading: true });
      try {
        let { data } = await axios.post(
          process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/wiki/countryData",
          {
            countries: rootGetters.getSelectedCountries.map(
              country => country.country_id
            ),
            policyLimit: rootGetters.getPolicyLimit,
            classType: state.classType,
            policyClass: state.policyClass.policy_class_caption,
            currencyCode: state.selectedCurrencyCode,
            // Use the latest rate for now
            // dateOfRate: rootGetters.dateOfCurrencyRate
          },
          {
            withCredentials: true
          }
        );
        commit("setLoadingWikiData", { loading: false });
        return data;
      } catch (err) {
        commit("setLoadingWikiData", { loading: false });
        console.log("AXIOS country wiki data error");
        console.error(err);
      }
    },
    async generateWikiCapabilitiesData({ dispatch, commit, rootGetters }) {
      commit("setLocalPolicies", { localPolicies: [] });
      let capabilitiesData = [];
      const wikiData = await dispatch("getWikiCapabilitiesData");

      const pickRandom = function(things) {
        return things[Math.floor(Math.random() * things.length)];
      };

      commit("setCurrencyData", wikiData.currencyData);
      commit("setPolicyCurrencyData", wikiData.currencyData);

      const countryData = wikiData.countryData;

      capabilitiesData = rootGetters.getSelectedCountries
        .filter(country => {
          const dbData = countryData.find(
            d => d.options.country.country_id == country.country_id
          );
          if (!dbData) {
            console.log(
              "couldn't find db data for the country with ID: " +
                country.country_id
            );
            return false;
          }
          return true;
        })
        .map(country => {
          const dbData = countryData.find(
            d => d.options.country.country_id == country.country_id
          );

          const check = {
            options: dbData.options,
            overallLimit: "",
            overallDeductible: "",
            turnover: 0,
            employees: 0,
            covers: {},
            showExtra: false
          };

          for (let i = 1; i <= 8; i++) {
            // FIXME: Again, need to use real data here
            const allowInclude = pickRandom([
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              false
            ]);
            check.covers[i] = {
              id: i,
              included: 1,
              occurrenceLimit: 1234567,
              aggregateLimit: 7654321,
              maxDeductible: 321,
              minDeductible: 123,
              percentageDeductible: 3,
              allowInclude,
              allowExclude: !allowInclude
            };
          }

          let id = 1;
          for (let item of Object.keys(check.options)) {
            if (
              !Object.prototype.hasOwnProperty.call(check.options[item], "id")
            ) {
              check.options[item].id =
                check.options.country.id + "capability" + id;
              id++;
              check.options[item].userValue = null;
            }
          }

          return check;
        });
      capabilitiesData.forEach(d => {
        if (d === undefined) {
          console.log("found undefined");
        }
      });
      dispatch("createLocalPoliciesFromWikiData", {
        wikiData: capabilitiesData
      });
    },
    async changeClassOfBusiness({ commit, dispatch, getters }, payload) {
      commit("changeClassOfBusiness", payload);
      let policyClass = getters.policyClass;
      commit("setPolicyClasses", {policyClasses: []});
      commit("changePolicyCLass", {policyClass: null});
      // Load the policy classes for this class type
      try {
        const policyClassesResponse = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT +
            "/wiki/policyClasses/" +
            getters.classType,
            {
              withCredentials: true
            }
        );
        if (policyClassesResponse.status === 200) {
          commit("setPolicyClasses", { policyClasses: policyClassesResponse.data });
          dispatch("changePolicyClass", { policyClass });
        } else {
          console.error(policyClassesResponse);
          console.error("There was an error with the above request");
        }
      } catch (err) {
        console.log("AXIOS policy classes data error");
        console.error(err);
      }
    },
    changePolicyClass({ commit, state }, {policyClass}) {
      if (policyClass === null) {
        commit("changePolicyCLass", {policyClass});
        return;
      }
      const bPolicyClassAvailable = state.policyClasses.some(
          policyClass => policyClass.policy_class_caption === policyClass.policy_class_caption
      );
      if (bPolicyClassAvailable) {
        commit("changePolicyCLass", {policyClass});
      }
    },
    setCurrencyCode({commit, dispatch}, { code }) {
      commit('setCurrencyCode', { code });
      // Reset the policy limit on currency change
      dispatch('changePolicyLimit', {policyLimit: 0});
    },
    // @deprecated Need to remove this in the future
    async loadCurrencyCodes({ state, commit }) {
      if (state.currencyCodes.length === 0) {
        try {
          let { data } = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/wiki/currencyCodes",
            {
              withCredentials: true
            }
          );
          commit("setCurrencyCodes", { currencyCodes: data });
        } catch (err) {
          console.log("AXIOS wiki currency code error:");
          console.error(err);
        }
      }
    },
    async loadCurrencies({ commit }) {
      try {
        let { data } = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/wiki/currencies",
            {
              withCredentials: true
            }
        );
        commit("setCurrencies", { currencies: data });
        // TODO: check if this is needed
        //  1. Could be the case where the currency selected is no longer available, but that's unlikely
        // dispatch("setCurrencyCode", { code: 'USD' })
      } catch (err) {
        console.log("AXIOS wiki currency code error:");
        console.error(err);
      }
    },
  },
  getters: {
    wikiStateForStorage: state => {
      return {
        contactDetails: state.contactDetails,
        classType: state.classType,
        policyClass: state.policyClass,
        selectedCurrencyCode: state.selectedCurrencyCode
      };
    },
    classType: state => state.classType,
    policyClass: state => state.policyClass,
    policyClasses: state => state.policyClasses,
    currencyCodes: state =>
        state.currencies.filter(currency =>
            currency.code.startsWith(state.currencyCodeFilter.toUpperCase())
    ).map(currency => currency.code),
    currenciesFiltered: state =>
        state.currencies.filter(currency =>
            currency.code.startsWith(state.currencyCodeFilter.toUpperCase())
    ),
    selectedCurrencyCode: state => state.selectedCurrencyCode,
    currencyCodeFilter: state => state.currencyCodeFilter,
    shouldShowPricingMatrixElements: state => state.classType === "Financial Lines",
    isLoadingWikiData: state => state.loadingWikiData,
    selectedCurrencyRate: state => {
      return state.selectedCurrencyRate;
    }
  }
};
