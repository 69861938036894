import axios from "axios";
import * as Sentry from "@sentry/vue"

export const user = {
  state: () => ({
    userObject: null,
    userChanges: false,
    verifyingToken: false,
    tokenExpired: false,
    emailSent: false,
    loggingOut: false,
    lastUrlName: "Country Select",
    loggedIn: false,
    timeAuthLastChecked: null
  }),
  mutations: {
    setUser(state, payload) {
      state.userObject = payload.user;
      state.verifyingToken = false;
      state.loggedIn = state.userObject !== null;
      state.userChanges = false;
    },
    setVerifyingToken(state, payload) {
      state.verifyingToken = payload.verifying;
    },
    setTokenExpired(state, payload) {
      state.tokenExpired = payload.expired;
    },
    setEmailSent(state, payload) {
      state.emailSent = payload.sent;
    },
    setLoggingOut(state, payload) {
      state.loggingOut = payload.loggingOut;
    },
    setLastUrl(state, payload) {
      state.lastUrlName = payload.name;
    },
    updateDetails(state, payload) {
      if (payload.forename) {
        state.userObject.forename = payload.forename;
        state.userChanges = true;
      }
      if (payload.surname) {
        state.userObject.surname = payload.surname;
        state.userChanges = true;
      }
      if (payload.office) {
        state.userObject.office = payload.office;
        state.userChanges = true;
      }
    },
    setLastAuthTime(state, payload) {
      state.timeAuthLastChecked = payload.time;
    }
  },
  actions: {
    async setUserObject({ commit }) {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/user";

      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        commit("setUser", { user: data });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async loginWithToken({ commit }, payload) {
      let url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/verifyToken";

      commit("setTokenExpired", { expired: false });
      commit("setVerifyingToken", { verifying: true });
      try {
        let data;

        if (payload.token === process.env.VUE_APP_AUTH_COMPLEX_KEY) {
          data = {
            success: true,
            user: {
              id: 2,
              name: "Inquiry Tool User",
              forename: "Inquiry",
              surname: "User",
              email: "inquirytool@icede.co.uk",
              email_verified_at: null,
              created_at: "2021-10-07T08:43:14.000000Z",
              updated_at: "2021-10-07T08:43:14.000000Z",
              complex_key: process.env.VUE_APP_AUTH_COMPLEX_KEY
            },
            message: null
          };
        } else {
          if (payload.email) {
            url += "?email=" + payload.email;
          }
          ({ data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${payload.token}`
            },
            withCredentials: true
          }));
        }

        commit("setUser", { user: data.user });
        commit("setVerifyingToken", { verifying: false });
        commit("setEmailSent", { sent: false });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        commit("setTokenExpired", { expired: true });
        return false;
      }
      return true;
    },
    async logout({ commit, getters }) {
      const bLogoutOnServer = getters.isLoggedIn;
      commit("setUser", { user: null });
      commit("setLoggingOut", { loggingOut: true });
      commit("setEmailSent", { sent: false });

      if (!bLogoutOnServer) {
        commit("setLoggingOut", { loggingOut: false });
        return;
      }

      try {
        await axios.get(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/logout", {
          withCredentials: true
        });

        commit("setLoggingOut", { loggingOut: false });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async checkAuthStatus({ state, commit }) {
      if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "1") {
        if (state.userObject !== null && state.userObject.complex_key === process.env.VUE_APP_AUTH_COMPLEX_KEY) {
          // Keep the user authenticated if we're using complex key auth and their key matches the environment variable.
          return;
        } else {
          commit("setUser", { user: null });
        }
      }

      if (!state.loggingOut && !state.verifyingToken) {
        try {
          let { data } = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/user",
            {
              withCredentials: true
            }
          );
          if (data.loggedIn && !state.loggingOut) {
            commit("setUser", { user: data.user });
          } else {
            commit("setUser", { user: null });
          }
          commit("setLastAuthTime", { time: Date.now() });
        } catch (err) {
          console.log("User not logged in");
          Sentry.captureException(err);
          // console.error(err);
          commit("setUser", { user: null });
        }
      } else {
        if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "0") {
          console.log("User logging out");
        }
      }
    }
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
    user: state => state.userObject,
    verifying: state => state.verifyingToken,
    tokenExpired: state => state.tokenExpired,
    emailSent: state => state.emailSent,
    loggingOut: state => state.loggingOut,
    lastUrlName: state => state.lastUrlName,
    timeAuthLastChecked: state => state.timeAuthLastChecked,
    userIsProducing: state => state.userObject?.flagProducingInquiryUser ?? false,
  }
};
