<template>
  <div class="programme-instructions">
    <div class="row mt-2">
      <div class="col">
        <h5 class="mt-2">Programme Instructions</h5>
        <div class="form-group row">
          <label for="underwriter" class="col-sm-2 col-form-label"
            >Underwriter</label
          >
          <div class="col-sm-3">
            <input
              id="underwriter"
              class="form-control"
              type="text"
              :value="emailAddress"
              disabled="disabled"
            />
          </div>
          <label for="partnerName" class="col-sm-2 col-form-label"
            >Partner Name</label
          >
          <div class="col-sm-3">
            <input
              id="partnerName"
              class="form-control"
              type="text"
              :value="partnerName"
              disabled="disabled"
            />
          </div>

        </div>
        <div class="form-group row">
          <label for="clientName" class="col-sm-2 col-form-label"
            >Client Name</label
          >
          <div class="col-sm-3">
            <input
              id="clientName"
              class="form-control"
              type="text"
              disabled="disabled"
              :value="clientName"
            />
          </div>
          <label for="clientRef" class="col-sm-2 col-form-label"
            >Client ID/Reference</label
          >
          <div class="col-sm-3">
            <input
              id="clientRef"
              class="form-control"
              type="text"
              :value="clientRef"
              disabled="disabled"
            />
          </div>

        </div>
        <div class="form-group row">
          <label for="inceptionDate" class="col-sm-2 col-form-label"
            >Inception Date</label
          >
          <div class="col-sm-3">
            <date-picker
              :value="
                inceptionDate &&
                  new Date(
                    inceptionDate.getTime() -
                      inceptionDate.getTimezoneOffset() * 60 * 1000
                  )
                    .toISOString()
                    .split('T')[0]
              "
              placeholder="Inception Date"
              :monday-first="true"
              format="dd MMM yyyy"
              disabled="disabled"
              input-class="date-picker-input"
            ></date-picker>
          </div>
          <label for="expiryDate" class="col-sm-2 col-form-label"
            >Expiry Date</label
          >
          <div class="col-sm-3">
            <date-picker
              placeholder="Expiry Date"
              :monday-first="true"
              format="dd MMM yyyy"
              :value="
                $store.state.localPolicies.expiryDate
                  ? $store.state.localPolicies.expiryDate
                  : new Date()
              "
              :disabled-dates="{
                to: new Date()
              }"
              input-class="date-picker-input"
              @input="value => sendInput(value, 'expiryDate')"
            ></date-picker>
          </div>
        </div>
        <div class="form-group row">
          <label for="rateOfExchange" class="col-sm-2 col-form-label"
            >Rate of Exchange</label
          >
          <div class="col-sm-3">
            <date-picker
              placeholder="Date of Rate"
              :monday-first="true"
              :value="dateOfRate"
              format="dd MMM yyyy"
              :disabled-dates="{ from: new Date() }"
              @input="value => sendInput(value, 'dateOfRate')"
              input-class="date-picker-input"
            ></date-picker>
            <small
              v-if="$store.state.localPolicies.dateUnavailable !== null"
              class="text-danger"
            >
              Currency rate unavailable for
              {{ $store.getters.dateOfRateUnavailable }}
            </small>
          </div>
          <div v-if="dateOfRateChanged" class="col-sm-2">
            <button
              @click="reloadCapabilities"
              v-b-tooltip.hover.top="{
                title: 'Reload with new currency rate date',
                variant: 'success'
              }"
              class="btn btn-success btn-sm btn-fill mt-1"
            >
              <i class="fa fa-sync"></i>&nbsp;Reload
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <table
            id="footableInstructions"
            class="table table-responsive table-bordered table-hover"
          >
            <thead>
              <tr>
                <th :colspan="usingIssuingOffice() ? 2 : 1" class="border-right"></th>
                <th class="border-right text-center">Local Client Details</th>
                <th v-if="usingPolicyTypeSelection()" colspan="3" class="border-right text-center">
                  Policy Required
                </th>
                <th :colspan="financeColspan()" class="border-right text-center">Finance</th>
                <th colspan="3" class="text-center">Coverage</th>
              </tr>
              <tr>
                <th :class="{'border-right': !usingIssuingOffice()}">Country</th>
                <th v-if="usingIssuingOffice()" class="border-right">Issuing Office</th>
                <th class="border-right">Reference / ID</th>
                <th v-if="usingPolicyTypeSelection()">Local Policy</th>
                <th v-if="usingPolicyTypeSelection()">Non-Admitted</th>
                <th v-if="usingPolicyTypeSelection()" class="border-right">Freedom of Service</th>
                <th>Indicative Premium</th>
                <th v-if="usingContribution()">Contribution</th>
                <th>Local Broker Appointed</th>
                <th>Brokerage %</th>
                <th :class="{'border-right': !usingTacitRenewal()}">Local or Central Collection</th>
                <th v-if="usingTacitRenewal()" class="border-right">Tacit Renewal</th>
                <th>Indicative Limits</th>
                <th>Good Local Standard</th>
                <th>Specific Coverage Req.</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="tblInstructions">
              <tr v-if="localPolicies.length === 0">
                <td colspan="12">
                  <i class="fa fa-sync fa-spin"></i>&nbsp;Reloading Capabilities
                  with the selected currency rate date...
                </td>
              </tr>
              <tr
                id="trInstructions"
                v-for="data in localPolicies"
                :key="data.options.country.id"
              >
                <td class="country">
                  {{ data.options.country.value }}
                </td>
                <td v-if="usingIssuingOffice()">
                  <div
                    v-if="officesForCountry(data.options.country.country_id).length < 2"
                  >
                    <small v-if="officesForCountry(data.options.country.country_id).length === 0">No offices found for this country</small>
                    <span v-else>{{ officesForCountry(data.options.country.country_id)[0].regional_company_name }}</span>
                  </div>
                  <select
                    v-else
                    class="form-control form-control-sm"
                    :value="
                      data.options.company.userValue
                        ? data.options.company.userValue
                        : ''
                    "
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'company',
                        value: $event.target.value
                      })
                    "
                  >
                    <option value="">Issuing Office</option>
                    <option v-for="office in officesForCountry(data.options.country.country_id)" :key="office.company_id" :value="office.company_id">
                      {{ office.regional_company_name }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    autocomplete="off"
                    maxlength="500"
                    class="form-control form-control-sm reference"
                    placeholder="Ref"
                    :value="data.options.reference.userValue"
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'reference',
                        value: $event.target.value
                      })
                    "
                  />
                </td>
                <td v-if="usingPolicyTypeSelection()">
                  <input
                    :disabled="
                      checkDisabled(data.options.country.id, 'localPolicy')
                    "
                    type="checkbox"
                    class="form-control"
                    :checked="data.options.localPolicy.userValue"
                    @change="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'localPolicy',
                        value: $event.target.checked
                      })
                    "
                  />
                </td>
                <td v-if="usingPolicyTypeSelection()">
                  <input
                    :disabled="
                      checkDisabled(data.options.country.id, 'nonAdmitted')
                    "
                    type="checkbox"
                    class="form-control"
                    :checked="data.options.nonAdmitted.userValue"
                    @change="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'nonAdmitted',
                        value: $event.target.checked
                      })
                    "
                  />
                </td>
                <td v-if="usingPolicyTypeSelection()">
                  <input
                    :disabled="checkDisabled(data.options.country.id, 'fos')"
                    type="checkbox"
                    class="form-control"
                    :checked="data.options.fos.userValue"
                    @change="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'fos',
                        value: $event.target.checked
                      })
                    "
                  />
                </td>
                <td class="indicativePremium"
                    :id="'indicativePremium' + data.options.country.id"
                    :class="{'bg-warning': (data.options.quoteRequired && data.options.quoteRequired.value === 'Yes'), 'cursor-pointer': (data.options.quoteRequired && data.options.quoteRequired.value === 'Yes')}"
                >
                  <b-tooltip :target="'indicativePremium' + data.options.country.id"
                             triggers="hover"
                             :disabled="(!data.options.quoteRequired || data.options.quoteRequired.value !== 'Yes')"
                  >
                    Quote Required
                  </b-tooltip>
                  <div
                    class="mb-1"
                    v-if="usingPremiumMatrix"
                  >
                    <small>{{ $options.filters.formatCurrency(data.options.indicativePremium.premium_from, 6)
                    + ' - '
                    + $options.filters.formatCurrency(data.options.indicativePremium.premium_to, 6)}}</small>
                  </div>
                  <currency-formatted-input
                    :value="indicativePremium(data.options.country.id)"
                    @input="
                      value => {
                        return validatePremium(
                            data.options.country.id,
                            value,
                            false
                        );
                        }
                    "
                    @focusout="
                      value => {
                        return validatePremium(
                            data.options.country.id,
                            value,
                            true
                        );
                        }
                    "
                    inputClass="form-control-sm"
                    :variant="validatePremium(
                        data.options.country.id,
                        indicativePremium(data.options.country.id),
                        false
                        )
                        || !usingPremiumMatrix
                        ? ''
                        : 'danger'"
                    placeholder="Premium"
                  ></currency-formatted-input>
                </td>
                <td v-if="usingContribution()" class="contribution"
                    :id="'contribution' + data.options.country.id"
                >
                  <currency-formatted-input
                    @input="
                    value =>
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'contribution',
                        value
                      })
                    "
                    inputClass="form-control-sm"
                    placeholder="Contribution"
                  ></currency-formatted-input>
                </td>
                <td>
                  <div
                    v-if="data.options.brokerReq.value === 'Yes'"
                    v-b-tooltip.hover.top="{
                      title: 'Broker Required',
                      variant: 'info'
                    }"
                  >
                    <select
                      disabled="disabled"
                      class="form-control form-control-sm"
                    >
                      <option value="0" selected="selected">Yes</option>
                    </select>
                  </div>
                  <select
                    v-else
                    class="brokerAppointedCheck form-control form-control-sm"
                    :value="
                      data.options.brokerReq.userValue
                        ? data.options.brokerReq.userValue
                        : 'No'
                    "
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'brokerReq',
                        value: $event.target.value
                      })
                    "
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    max="100.00"
                    maxlength="6"
                    size="6"
                    style="width: 90px;"
                    placeholder="%"
                    class="form-control form-control-sm"
                    :value="
                      data.options.brokeragePc.userValue
                        ? data.options.brokeragePc.userValue
                        : ''
                    "
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'brokeragePc',
                        value: $event.target.value
                      })
                    "
                  />
                </td>
                <td>
                  <div
                    v-if="data.options.cetColl.value === 'Yes'"
                    v-b-tooltip.hover.top="{
                      title: 'Local Collection Required',
                      variant: 'info'
                    }"
                  >
                    <select
                      disabled="disabled"
                      class="form-control form-control-sm"
                    >
                      <option value="0" selected="selected">Local</option>
                    </select>
                  </div>
                  <select
                    v-else
                    class="centralCollectionSelect form-control form-control-sm"
                    :value="
                      data.options.cetColl.userValue
                        ? data.options.cetColl.userValue
                        : 'TBC'
                    "
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'cetColl',
                        value: $event.target.value
                      })
                    "
                  >
                    <option value="TBC" selected="selected">TBC</option>
                    <option value="Local">Local</option>
                    <option value="Central">Central</option>
                  </select>
                </td>
                <td>
                  <div
                    v-if="data.options.tacitRenew.value === 'Yes'"
                    v-b-tooltip.hover.top="{
                      title: 'Tacit Renewal',
                      variant: 'info'
                    }"
                  >
                    <select
                      disabled="disabled"
                      class="form-control form-control-sm"
                    >
                      <option value="0" selected="selected">Yes</option>
                    </select>
                  </div>
                  <select
                    v-else
                    class="form-control form-control-sm"
                    :value="
                      data.options.tacitRenew.userValue
                        ? data.options.tacitRenew.userValue
                        : 'No'
                    "
                    @input="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'tacitRenew',
                        value: $event.target.value
                      })
                    "
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </td>
                <td>
                  <div v-if="data.options.indicativeLimits" class="indicativeLimits">
                    {{ data.options.indicativeLimits.value | formatCurrency(2) }}
                    <br v-if="data.options.indicativeLimits.additional" />
                    <small
                        v-html="data.options.indicativeLimits.additional"
                    ></small>
                  </div>
                  <div v-else>
                    &nbsp;-&nbsp;
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="form-control goodLocalStandardCheck"
                    :checked="
                      data.options.goodLocalStandard.userValue
                        ? data.options.goodLocalStandard.userValue
                        : false
                    "
                    @change="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'goodLocalStandard',
                        value: $event.target.checked
                      })
                    "
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="form-control coverageRequirementsCheck"
                    :checked="
                      data.options.specificCovReq.userValue
                        ? data.options.specificCovReq.userValue
                        : false
                    "
                    @change="
                      $store.commit('setInstructionData', {
                        id: data.options.country.id,
                        field: 'specificCovReq',
                        value: $event.target.checked
                      })
                    "
                  />
                </td>
                <td style="height: 100%;">
                  <button
                    class="mb-1 btn btn-primary btn-sm text-nowrap"
                    @click="
                      $store.dispatch('duplicateLocalPolicy', {
                        countryId: data.options.country.id
                      })
                    "
                  >
                    <i class="fa fa-copy"></i>&nbsp;Duplicate
                  </button>
                  <button
                    class="btn btn-warning btn-sm text-nowrap"
                    @click="
                      $store.dispatch('removeLocalPolicy', {
                        countryId: data.options.country.id
                      })
                    "
                  >
                    <i class="fa fa-trash"></i>&nbsp;Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
<!-- Local Contact Details    -->
    <div class="row">
      <div class="col">
        <h5>
          Local Contact Details&nbsp;
          <button
            @click="() => (contactsVisible = !contactsVisible)"
            class="btn btn-secondary btn-sm"
          >
            Toggle Contacts Visibility&nbsp;
            <i v-if="contactsVisible" class="fa fa-caret-down"></i>
            <i v-else class="fa fa-caret-up"></i>
          </button>
        </h5>
        <b-card no-body class="mb-1">
          <b-collapse
            id="accordion-1"
            :visible="contactsVisible"
            role="tabpanel"
          >
            <b-card-body>
              <div class="mt-2">
                <table id="footableContacts" class="table table-hover">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Contact Details</th>
                      <th>No Broker</th>
                      <th>Contact Name</th>
                      <th>Company Name</th>
                      <th>Address</th>
                      <th>Telephone</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody id="tblContacts">
                    <tr
                      v-for="data in visibleContacts"
                      :key="data.id"
                      :id="data.id"
                    >
                      <td
                        v-for="(cell, contactCol) in data.data"
                        :key="cell.id"
                        :rowspan="cell.rowspan"
                      >
                        <span v-if="cell.type === 'text'">{{
                          cell.value
                        }}</span>
                        <input
                          v-else-if="cell.type === 'input'"
                          type="text"
                          autocomplete="off"
                          maxlength="500"
                          class="form-control form-control-sm"
                          @input="
                            $store.commit('setContactDetail', {
                              col: contactCol,
                              id: data.id,
                              value: $event.target.value
                            })
                          "
                          :placeholder="cell.placeholder"
                          :value="cell.value"
                        />
                        <button
                          v-else-if="cell.type === 'button'"
                          class="btn btn-sm btn-warning"
                          @click="
                            $store.dispatch('removeBrokerContact', {
                              id: data.id
                            })
                          "
                          v-b-tooltip.hover.top="{
                            title: 'Remove local broker row',
                            variant: 'info'
                          }"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <k-c-template></k-c-template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
<!-- Button Row    -->
    <inquiry-actions>
      <button
        v-if="usingCoverageElements()"
        class="btn btn-success float-right"
        :disabled="saving || !$store.getters.programmeInstructionsActive"
        @click="goToCoverSelect"
      >
        <i class="fa fa-pen"></i>&nbsp; Input Cover
      </button>
      <span v-else class="float-right">
        <span v-if="$store.getters.submitting">
          <i class="fa fa-sync fa-spin"></i>&nbsp;Submitting Programme...
        </span>
        <tooltip-button
          v-else
          text="Submit"
          icon="paper-plane"
          btn-class="btn-success"
          :disabled="$store.getters.submitted"
          disabled-tooltip-text="This programme has already been submitted"
          disabled-tooltip-level="success"
          disabled-tooltip-position="left"
          @click="submitProgramme"
        ></tooltip-button>
      </span>
    </inquiry-actions>
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      subtitle="Please login before you save"
      login-button-text="Login and Save"
      @logged-in="saveReport"
    ></login-modal>
  </div>
</template>

<style scoped>
input[type="checkbox"].form-control {
  width: 50px;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0.6rem;
}

input[type="text"].form-control.form-control-sm {
  color: #111;
}
input[type="text"].form-control.form-control-sm::placeholder {
  color: #aaa;
}
</style>

<script>
import CurrencyFormattedInput from "./CurrencyFormattedInput";
import TooltipButton from "./TooltipButton";
import LoginModal from "./LoginModal";
import KCTemplate from "./KeyContacts/KCTemplate";
import FeaturesMixin from '@/mixins/features.mixin';
import InquiryActions from "./InquiryActions";
export default {
  name: "ProgrammeInstructions",
  components: { KCTemplate, TooltipButton, CurrencyFormattedInput, LoginModal, InquiryActions },
  mixins: [FeaturesMixin],
  data: () => {
    return {
      saving: false,
      contactsVisible: false
    };
  },
  computed: {
    localPolicies() {
      return this.$store.getters.localPolicies;
    },
    partnerName() {
      return this.$store.state.localPolicies.partnerName;
    },
    clientName() {
      return this.$store.state.localPolicies.clientName;
    },
    clientRef() {
      return this.$store.state.localPolicies.clientRef;
    },
    emailAddress() {
      return this.$store.state.localPolicies.emailAddress;
    },
    inceptionDate() {
      let date = this.$store.state.localPolicies.inceptionDate;
      if (!date) {
        date = new Date();
      } else {
        date = new Date(date);
      }
      return date;
    },
    dateOfRate() {
      let date = this.$store.state.localPolicies.dateOfRate;
      if (typeof date !== typeof new Date()) {
        return new Date(date);
      }
      if (!date) {
        date = new Date();
      }
      return date;
    },
    dateOfRateChanged() {
      return (
        this.$store.state.localPolicies.displayedDateOfRate.valueOf() !==
        this.$store.state.localPolicies.dateOfRate.valueOf()
      );
    },
    visibleContacts() {
      return this.$store.getters.localContactDetails.filter(
        cont => typeof cont.visible === "undefined" || cont.visible
      );
    },
    usingPremiumMatrix() {
      return this.$store.getters.shouldShowPricingMatrixElements 
        && process.env.VUE_APP_ICEDE_CLIENT === process.env.VUE_APP_ICEDE_CLIENT_TMG;
    },
  },
  methods: {
    checkDisabled(countryId, check) {
      const pol = this.$store.getters.localPolicies.find(
        pol => pol.options.country.id === countryId
      );
      if (!pol) {
        return true;
      }
      const others = ["localPolicy", "nonAdmitted", "fos"].filter(
        type => type !== check
      );
      if (pol.options[check].value === "No") {
        return true;
      } else {
        for (let type of others) {
          if (pol.options[type].userValue) {
            return true;
          }
        }
        return false;
      }
    },
    indicativePremium(countryId) {
      const pol = this.$store.getters.localPolicies.find(
        pol => pol.options.country.id === countryId
      );
      if (!pol) {
        return 0;
      }
      if (!pol.options.indicativePremium) {
        return '';
      } else if (pol.options.indicativePremium.userValue === null) {
        return (pol.options.indicativePremium.premium_from + pol.options.indicativePremium.premium_to) / 2;
      } else {
        return pol.options.indicativePremium.userValue;
      }
    },
    validatePremium(countryId, inputPremium, showToast = false) {
      if (isNaN(inputPremium)) {
        inputPremium = 0;
      }

      if (!this.usingPremiumMatrix) {
        this.setInstructionData(countryId, 'indicativePremium', inputPremium.length === 0 ? 0 : parseFloat(inputPremium));
        return;
      }

      const pol = this.$store.getters.localPolicies.find(
          pol => pol.options.country.id === countryId
      );
      const premiumFrom = pol.options.indicativePremium.premium_from;
      const premiumTo = pol.options.indicativePremium.premium_to;

      let minimum = pol.options.indicativePremium.min_premium;
      if ((pol.options.indicativePremium.min_rate * pol.options.indicativeLimits.value) > minimum) {
        minimum = pol.options.indicativePremium.min_rate * pol.options.indicativeLimits.value;
      }
      const belowMinimum = inputPremium < minimum;
      if (showToast && belowMinimum) {
        this.$bvToast.toast(
            `Premium input for ${pol.options.country.value} is below the minimum (${this.$options.filters.formatCurrency(minimum, 6)}) for this policy limit and class`,
            {
              title: "Premium Below Minimum",
              variant: "danger",
              autoHideDelay: 15000
            }
        );
        inputPremium = minimum;
      }

      const insideRange = (inputPremium > premiumFrom && inputPremium <= premiumTo);
      if (showToast && !insideRange && !belowMinimum) {
        this.$bvToast.toast(
            `Premium input for ${pol.options.country.value} is outside of the recommended range for this policy limit and class`,
            {
              title: "Premium Outside Recommendation",
              variant: "warning",
              autoHideDelay: 15000
            }
        );
        return false;
      }
      this.setInstructionData(countryId, 'indicativePremium', inputPremium.length === 0 ? 0 : parseFloat(inputPremium));
      return insideRange && !belowMinimum;
    },
    setInstructionData(id, field, value) {
       this.$store.commit('setInstructionData', {id, field, value});     
    },
    goToCoverSelect() {
      this.$router.push({ name: "Cover Select" });
    },
    uncheckAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: false
      });
      this.$router.replace({ name: "Country Select" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
    async submit() {
      this.$store.commit("setProgrammeSubmitted");
      await this.$store.dispatch("storeState");
      await this.$router.push({ name: "Programmes" });
    },
    async submitProgramme() {
      if (!this.$store.getters.isLoggedIn) {
        this.action = "Submit";
        this.$bvModal.show("modal-login");
      } else {
        await this.submit();
      }
    },
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;
      this.$store.dispatch("setPolicyDetail", payload);
    },
    async reloadCapabilities() {
      await this.$store.dispatch("generateWikiCapabilitiesData");
      if (this.usingCoverageElements()) {
        await this.$store.dispatch("getCoverageTemplates");
      }
    },
    financeColspan() {
      let colspan = 4;
      if (this.usingTacitRenewal()) {
        colspan++;
      }
      if (this.usingContribution()) {
        colspan++;
      }
      return colspan;
    },
    officesForCountry(country_id) {
      return this.$store.getters.officesForCountry(country_id)
    }
  },
  mounted() {
    // Redirect to capabilities tab if we don't have the data for this tab
    if (!this.$store.getters.programmeInstructionsActive) {
      this.$router.replace({ name: "Capabilities" });
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });
  }
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
