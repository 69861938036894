<template>
  <div
    v-if="!disabled"
    v-b-tooltip="activeTooltip"
    style="display: inline-block"
  >
    <button
      class="btn"
      :class="btnClass"
      @click="$emit('click', $event)"
      v-html="buttonText"
    >
      {{ buttonText }}
    </button>
  </div>
  <div v-else style="display: inline-block">
    <div v-b-tooltip="disabledTooltip" style="display: inline-block">
      <button
        class="btn"
        :class="btnClass"
        disabled="disabled"
        v-html="buttonText"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TooltipButton",
  props: {
    text: String,
    icon: String,
    btnClass: String,
    tooltipText: String,
    tooltipLevel: String,
    tooltipPosition: String,
    disabled: Boolean,
    disabledTooltipText: String,
    disabledTooltipLevel: String,
    disabledTooltipPosition: String
  },
  computed: {
    buttonText() {
      let buttonText = this.text;
      if (this.icon !== "") {
        buttonText =
          '<i class="fa fa-' + this.icon + '"></i>&nbsp;' + buttonText;
      }
      return buttonText;
    },
    activeTooltip() {
      if (this.tooltipText) {
        const tooltip = {
          title: this.tooltipText,
          trigger: "hover"
        };
        if (this.tooltipLevel) {
          tooltip["variant"] = this.tooltipLevel;
        }
        tooltip["placement"] = "top";
        if (this.tooltipPosition) {
          tooltip["placement"] = this.tooltipPosition;
        }
        return tooltip;
      }

      return null;
    },
    disabledTooltip() {
      if (this.disabledTooltipText) {
        const tooltip = {
          title: this.disabledTooltipText,
          trigger: "hover"
        };
        if (this.disabledTooltipLevel) {
          tooltip["variant"] = this.disabledTooltipLevel;
        }
        tooltip["placement"] = "top";
        if (this.disabledTooltipPosition) {
          tooltip["placement"] = this.disabledTooltipPosition;
        }
        return tooltip;
      }
      return null;
    }
  },
  methods: {
    format(value) {
      if (value !== "" && !isNaN(value)) {
        return new Intl.NumberFormat("en-gb", {
          maximumFractionDigits: 2
        }).format(value);
      } else {
        return value;
      }
    },
    onInput(event) {
      const numValue = event.target.value.replace(/[^\d,.]/g, "");
      this.formattedValue = this.format(numValue);
      this.rawValue = numValue;
      this.$emit("input", numValue);
    },
    focusIn() {
      this.focused = true;
    },
    focusOut() {
      this.focused = false;
    }
  }
};
</script>
