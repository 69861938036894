import store from '../store';

const FeaturesMixin = {
    methods: {
        isClient(...clientKeys) {
            for (let clientKey of clientKeys) {
                if (process.env.VUE_APP_ICEDE_CLIENT === clientKey) {
                    return true
                }
            }
            return false
        },
        inquiryOnlyMode() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        usingComplexKeyAuth() {
            return parseInt(process.env.VUE_APP_USING_COMPLEX_KEY_AUTH) === 1;
        },
        usingPricingMatrix() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        usingCoverageElements() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingPolicyTypeSelection() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingTacitRenewal() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingContribution() {
            // return false;
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingIssuingOffice() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA)
              && store.getters.userIsProducing;
        },
    }
}

export default FeaturesMixin
