<template>
  <div class="capabilities-checker">
    <div class="row">
      <div class="col">
        <h5 class="my-2">Capabilities Check</h5>
        <div class="panel-body mb-2">
          <transition name="fade">
            <div v-if="$store.getters.isLoadingWikiData" class="my-4">
              <span id="imgLoading">
                <i class="fa fa-sync fa-spin"></i>&nbsp; Getting capabilities data...
              </span>
            </div>
            <div v-else-if="$store.getters.localPolicies.length === 0" class="py-3">
              <strong><i class="fa fa-info-circle"></i>&nbsp;No wiki data found for the selected countries</strong>
            </div>
            <div v-else class="mt-3">
              <strong v-if="countriesMissingWikiData.length > 0">
                <i class="fa fa-info-circle"></i>&nbsp;No wiki data found for countries: {{ countriesMissingWikiData }}
              </strong>
              <div class="table-responsive-xl">
              <table class="table table-responsive table-bordered table-hover">
                <thead>
                  <tr class="footable--group-row">
                    <th data-group="group1" class="border-right"></th>
                    <th
                      data-group="group2"
                      colspan="3"
                      class="border-right text-center"
                    >
                      Coverage Types
                    </th>
                    <th
                      data-group="group3"
                      colspan="4"
                      class="border-right text-center"
                    >
                      Local Requirements
                    </th>
                    <th data-group="group4" colspan="2" data-breakpoints="ty xs sm md" class="border-right">
                      Minimum Requirements
                    </th>
                    <th data-group="group5" colspan="2" class="border-right"></th>
                  </tr>
                  <tr>
                    <th data-group="group1" class="border-right">Country</th>
                    <th data-group="group2" style="width: 90px;">Local Policy</th>
                    <th
                      data-group="group2"
                      style="width: 90px;"
                      data-breakpoints="ty xs sm"
                    >
                      Non-Admitted
                    </th>
                    <th
                      data-group="group2"
                      style="width: 90px;"
                      class="border-right"
                    >
                      Freedom of Service
                    </th>
                    <th data-group="group3">Broker Required</th>
                    <th data-group="group3">Central Collection</th>
                    <th data-group="group3" data-breakpoints="ty xs sm">
                      Cash Before Cover
                    </th>
                    <th data-group="group3" class="border-right">
                      Local Retention / Reinsurance
                    </th>
                    <th data-group="group4" data-breakpoints="ty xs sm md">
                      Enforce Before
                    </th>
                    <th data-group="group4" data-breakpoints="ty xs sm md" class="border-right">
                      Required Instructions
                    </th>
                    <th data-group="group5" data-breakpoints="ty xs">
                      Indicative Premium ({{ $store.getters.selectedCurrencyCode }})
                    </th>
                    <th data-group="group5" class="border-right">
                      Policy Limit ({{ $store.getters.selectedCurrencyCode }})
                    </th>
                  </tr>
                </thead>
                <tbody id="tblCapabilities">
                  <tr
                    v-for="country in $store.getters.localPolicies"
                    :key="country.options.country.value"
                  >
                    <wiki-table-data
                      :wikiData="country.options.country"
                      :centered="false"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.localPolicy"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.nonAdmitted"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.fos"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.brokerReq"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.cetColl"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.cashBef"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="country.options.localRetReins"
                    ></wiki-table-data>
                    <wiki-table-data
                        :wikiData="country.options.enforceBefore"
                    ></wiki-table-data>
                    <wiki-table-data
                        :wikiData="{value: country.options.minimumRequirements.value.join(', '), level: country.options.minimumRequirements.level}"
                    ></wiki-table-data>
                    <wiki-table-data
                      :wikiData="{
                        value: (() => {
                          if (country.options.quoteRequired && country.options.quoteRequired.value === 'Yes') {
                            return 'Quote Required';
                          } else if (country.options.indicativePremium && country.options.indicativePremium.premium_to) {
                            return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, 6)
                              + ' - '
                              + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, 6)
                          } else {
                            return '';
                          }
                          })(),
                        additional: (() => {
                          if (country.options.quoteRequired
                            && country.options.quoteRequired.value === 'Yes'
                            && country.options.indicativePremium
                            && country.options.indicativePremium.premium_to
                            ) {
                            let sigFigFrom = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_from) + 2;
                            let sigFigTo = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_to) + 2;
                            return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, sigFigFrom)
                                + ' - '
                                + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, sigFigTo);
                          } else {
                            return '';
                          }
                          })(),
                        level: country.options.indicativePremium ? country.options.indicativePremium.level : 'info'
                      }"
                    ></wiki-table-data>
                    <wiki-table-data v-if="country.options.indicativeLimits"
                      :wikiData="country.options.indicativeLimits"
                    ></wiki-table-data>
                    <td v-else> - </td>
                    <td v-if="devMode">{{country.options.indicativePremium}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="row" v-if="!inquiryOnlyMode()">
      <div class="col">
        <h5>Save Report or Progress Local Placement</h5>
        <p>Saving the report enables you to retrieve it at a later date</p>
        <p>
          Instructing placement will alert your local Network Co-ordinator to
          commence the local policy quotes (where required)
        </p>
        <div class="form-group row">
          <label for="emailAddress" class="col-sm-2 col-form-label"
            >Your Email Address</label
          >
          <div class="col-sm-4">
            <input
              id="emailAddress"
              type="email"
              placeholder="Insert Email Address"
              autocomplete="off"
              :value="$store.getters.emailAddress"
              @input="sendInput($event.target.value, 'emailAddress')"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="partnerName" class="col-sm-2 col-form-label"
            >Partner Name</label
          >
          <div class="col-sm-4">
            <input
              id="partnerName"
              type="text"
              placeholder="Insert Partner Name"
              autocomplete="off"
              :value="$store.getters.partnerName"
              @input="sendInput($event.target.value, 'partnerName')"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="clientName" class="col-sm-2 col-form-label"
            >Client Name</label
          >
          <div class="col-sm-4">
            <input
              id="clientName"
              type="text"
              placeholder="Insert Client Name"
              autocomplete="off"
              :value="$store.getters.clientName"
              @input="sendInput($event.target.value, 'clientName')"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="clientId" class="col-sm-2 col-form-label"
            >Client ID/Reference</label
          >
          <div class="col-sm-4">
            <input
              id="clientId"
              type="text"
              placeholder="Insert Client Reference"
              autocomplete="off"
              :value="$store.getters.clientRef"
              @input="sendInput($event.target.value, 'clientRef')"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="inceptionDate" class="col-sm-2 col-form-label"
            >Inception Date</label
          >
          <div class="col-sm-4">
            <date-picker
              id="inceptionDate"
              :value="inceptionDate"
              placeholder="Inception Date"
              :monday-first="true"
              format="dd MMM yyyy"
              @input="value => sendInput(value, 'inceptionDate')"
              :disabled-dates="{ to: new Date() }"
              input-class="date-picker-input"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>
    <inquiry-actions v-if="!inquiryOnlyMode()">
      <button
        class="btn btn-success float-right"
        :disabled="saving || !$store.getters.programmeInstructionsActive || $store.getters.localPolicies.length === 0"
        @click="goToProgrammeInstructions"
      >
        <i class="fa fa-paper-plane"></i>&nbsp; Instruct Co-ordinator
      </button>
    </inquiry-actions>
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      subtitle="Please login before you save"
      login-button-text="Login and Save"
      @logged-in="saveReport"
    ></login-modal>
  </div>
</template>

<style scoped>
.capabilities-success {
  background-color: #52de73 !important;
}
.capabilities-danger {
  background-color: #ed616f !important;
  font-weight: bold;
}
.capabilities-warning {
  background-color: #fcc930 !important;
}
.table.b-table {
  height: 1200px;
}
</style>

<script>
import WikiTableData from "./WikiTableData";
import TooltipButton from "./TooltipButton";
import LoginModal from "./LoginModal";
import FeaturesMixin from "@/mixins/features.mixin";
import InquiryActions from "./InquiryActions";

export default {
  name: "CapabilitiesChecker",
  props: {
    capabilitiesData: Array,
    capabilitiesActive: Boolean
  },
  components: {
    WikiTableData,
    TooltipButton,
    LoginModal,
    InquiryActions
  },
  mixins: [FeaturesMixin],
  computed: {
    inceptionDate() {
      let inDate = this.$store.getters.inceptionDate;
      if (!inDate) {
        return "";
      }
      inDate = new Date(inDate);
      return new Date(inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split("T")[0];
    },
    countriesMissingWikiData() {
      let localPolicies = this.$store.getters.localPolicies;
      return this.$store.getters.getSelectedCountries.filter(
        country => {
          return !localPolicies.find(
            localPolicy =>
              localPolicy.options.country.country_id == country.country_id
          );
        }
      ).map(country => country.country_name)
      .join(', ');
    }
  },
  data: () => {
    return {
      saving: false,
      devMode: process.env.NODE_ENV !== "production"
    };
  },
  methods: {
		// TODO: Refactor me - This makes the input boxes feel really sluggish, as it's saving to the Vuex store with every character input
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;
      this.$store.dispatch("setPolicyDetail", payload);
    },
    goToProgrammeInstructions() {
      this.$router.push({ name: "Programme Instructions" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    }
  },
  mounted() {
    // Redirect to the country selection tab if we don't have the data for this tab
    if (!this.capabilitiesActive && !this.$store.getters.isLoadingWikiData) {
      this.$router.replace({ name: "Country Select" });
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });
  }
};
</script>
<style>
.table {
  display: block !important;
  overflow-x: auto !important;
  width: 100% !important;
}
</style>
