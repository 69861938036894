<template name="InquiryActions">
<div class="row mb-3 mt-2">
  <div class="col">
    <button
      class="btn btn-primary"
      :disabled="saving || $store.getters.submitted"
      @click="uncheckAllCountries"
    >
      <i class="fa fa-times"></i>&nbsp; Clear Selections
    </button>
  </div>
  <div v-if="showSave" class="col text-center">
    <span v-if="saving">
      <i class="fa fa-sync fa-spin"></i>&nbsp;Saving Programme...
    </span>
    <tooltip-button
      v-else
      text="Save Report Only"
      icon="save"
      btn-class="btn-outline-secondary"
      :disabled="$store.getters.submitted"
      disabled-tooltip-text="This programme has been submitted - changes cannot be saved"
      disabled-tooltip-level="info"
      disabled-tooltip-position="left"
      @click="saveReport()"
    ></tooltip-button>
  </div>
  <div class="col">
    <slot></slot>
  </div>
</div>
</template>
<script type="text/javascript">
import TooltipButton from "./TooltipButton";
export default {
  name: "InquiryActions",
  components: { TooltipButton },
  props: {
    showSave: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    saving: false,
  }),
  methods: {
    uncheckAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: false
      });
      this.$router.replace({ name: "Country Select" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
  },
};
</script>
